import React, { Component } from 'react';

import { Row, Column, Button as RebassButton, Message, Flex, Box } from 'rebass/emotion';
import withProps from 'recompose/withProps';
import Dropzone from 'react-dropzone';
import XLSX from 'xlsx';
import { HotTable } from '@handsontable/react';
import Prism from 'prismjs';
import 'prismjs/plugins/line-numbers/prism-line-numbers.min';

import Layout from '../components/Layout';
import Helmet from 'react-helmet';
import theme from '../theme';
import { standardButtonProps } from '../utils/style';
import 'handsontable/dist/handsontable.full.css';
import '../css/prism.css';

const ClearStateButton = withProps({ ...standardButtonProps, bg: theme.colors.blues[3] })(RebassButton);
const UploadButton = withProps({ ...standardButtonProps, bg: theme.colors.blues[2], ml: 3 })(RebassButton);

export default class App extends Component {
  state = {
    dropzoneActive: false,
    acceptedFiles: [],
    rejectedFiles: [],
    tableData: null,
    tableHeaders: null,
    lastSheet: null,
    showValidJSON: true,
    showJSONPreview: false,
    inputRef: 50,
    tinyJSON: [{ small: 'json' }],
    testData: [['2016', 10, 11, 12, 13], ['2017', 20, 11, 14, 13], ['2018', 30, 15, 12, 13]],
  };

  hotRef = React.createRef();
  textRef = React.createRef();
  previewRef = React.createRef();

  componentDidMount() {
    window.ondragenter = this.onDragStart;
    window.ondrop = this.onDragStop;
    window.dragend = this.onDragStop;
    Prism.highlightAll();
  }

  componentDidUpdate() {
    if (this.hotRef.current) {
      this.hotRef.current.hotInstance.loadData(this.state.tableData);
    }
    Prism.highlightAll();
  }

  createSelection = () => {
    if (document.body.createTextRange) {
      // ms
      let range = document.body.createTextRange();
      range.moveToElementText(this.previewRef.current);
      range.select();
    } else if (window.getSelection) {
      // moz, opera, webkit
      let selection = window.getSelection();
      let range = document.createRange();
      range.selectNodeContents(this.previewRef.current);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  onDragStart = () => {
    this.setState({ dropzoneActive: true });
  };

  onDragStop = () => {
    this.setState({ dropzoneActive: false });
  };

  onFileDrop = (acceptedFiles, rejectedFiles) => {
    acceptedFiles.forEach(file => {
      this.handleUploadFile(file);
    });
    this.setState({ acceptedFiles, rejectedFiles, dropzoneActive: false });
  };

  downloadFile = () => {
    let rows;
    if (this.state.tableData) {
      rows = this.state.tableData;
    }
    let csvContent = 'data:text/csv;charset=utf-8,';
    rows.forEach(row => {
      let subRow = row.join(',');
      csvContent += subRow + '\r\n';
    });
    let encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };

  handleUploadFile = file => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = () => {
      const fileAsBinaryString = reader.result;
      if (
        [
          'text/csv',
          'application/vnd.ms-excel',
          'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ].includes(file.type)
      ) {
        const workbook = XLSX.read(fileAsBinaryString, { type: 'binary' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const json = XLSX.utils.sheet_to_json(worksheet);
        const headers = Object.keys(json[0]);
        this.setState({ tableData: json, tableHeaders: headers });
      }
      if (file.type === 'application/json') {
        const json = JSON.parse(fileAsBinaryString);
        const headers = this.fetchHeaders(json);
        this.setState({ tableData: json, tableHeaders: headers });
      }
    };

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
  };

  isArray = object => {
    return Object.prototype.toString.call(object) === '[object Array]';
  };

  fetchHeaders = json => {
    // TODO: Handle failure to get headers gracefully
    let headers;
    if (this.isArray(json)) {
      headers = Object.keys(json[0]);
    } else if (typeof json === 'object') {
      headers = Object.keys(json);
    }
    return headers;
  };

  clearState = e => {
    e.preventDefault();
    this.setState({ tableData: null });
  };

  reinitState = () => {
    this.setState({ tableData: this.state.lastSheet });
  };

  /*******************************
   * JSON Validation functions
   ******************************/
  quoteKeys = input => {
    return input.replace(/(['"])?([a-zA-Z0-9_]+)(['"])?:/g, '"$2": ');
  };

  removeSmartQuotes = input => {
    return input.replace(/[“”]/g, '"');
  };

  removeTrailingComma = input => {
    return input.slice(-1) === ',' ? input.slice(0, -1) : input;
  };

  // Is there a closing brace and an opening brace with only whitespace between?
  isJSONLines = string => {
    return !!string.match(/\}\s+\{/);
  };

  linesToJSON = string => {
    return '[' + string.replace(/\}\s+\{/g, '}, {') + ']';
  };

  customParseJSON = input => {
    let result = input.trim();

    if (!result) return;
    try {
      result = JSON.parse(result);
    } catch (err) {
      this.setState({ showValidJSON: false });
      console.log(err);
      throw Error('Not parseable JSON');
    }

    if (!result) {
      result = this.quoteKeys(this.removeSmartQuotes(this.removeTrailingComma(result)));
      try {
        result = JSON.parse(result);
      } catch (err) {
        this.setState({ showValidJSON: false });
        console.log(err);
      }
    }

    if (!result && this.isJSONLines(result)) {
      try {
        result = this.linesToJSON(result);
        result = JSON.parse(result);
      } catch (err) {
        console.log(err);
        this.setState({ showValidJSON: false });
      }
    }

    return result;
  };

  flattenObject = (obj, path) => {
    if (path === undefined) path = '';

    var type = typeof obj;

    if (['array', 'object'].includes(type)) {
      var data = {};
      for (var i in obj) {
        var newData = this.flattenObject(obj[i], path + i + '/');
        // $.extend(d, newD);
      }
      return data;
    } else {
      var data = {};
      var endPath = path.substr(0, path.length - 1);
      data[endPath] = obj;
      return data;
    }
  };

  arrayFrom = json => {
    // let list = [],
    // next = json;
    // while (next) {
    //   let nextType = typeof next;
    //   if (nextType === 'array' && next.length) {
    //     let type = typeof next[0];
    //     let iterableTest = ['number', 'string', 'boolean', 'null'].includes(type);
    //     if (!iterableTest) return next;
    //   }
    //   if (nextType === 'object') {
    //     for (const key in next) list.push(next[key]);
    //   }
    //   next = list.shift();
    // }
    return [json];
  };

  processJSON = event => {
    // TODO: Handle value not enclosed by array.
    const value = event.currentTarget.value;
    const parsedJSON = this.customParseJSON(value) || [];
    const headers = Object.keys(parsedJSON[0]) || [];
    if (parsedJSON) {
      // const flattenedJSON = this.arrayFrom(parsedJSON);
      this.setState({
        showValidJSON: true,
        tableData: parsedJSON,
        tableHeaders: headers,
      });
    }
    // TODO?: Flatten JSON
  };

  handleClick = e => {
    this.hotRef.current.hotInstance.render();
  };

  render() {
    const { showValidJSON, showJSONPreview, dropzoneActive, tableData, tableHeaders } = this.state;
    const overlayStyle = {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      padding: '2.5em 0',
      fontFamily: `${theme.fonts.sans}`,
      fontWeight: 'bold',
      fontSize: `${theme.fontSizes[5]}px`,
      background: `${theme.colors.grayScale[2]}`,
      textAlign: 'center',
      color: `${theme.colors.grayScale[6]}`,
    };
    const dropZoneStyle = { width: '100%', height: '300px' };
    return (
      <Layout>
        <Helmet bodyAttributes={{ 'data-toolbar-order': 'select-text,download-file,label' }}>
          {/* <script src="prismjs/plugins/toolbar/prism-toolbar.min"></script> */}
        </Helmet>
        <Row>
          <Column width={[1, 1 / 8]} />
          <Column width={[1, 3 / 4]}>
            <Flex mb={3} alignItems="center">
              <ClearStateButton children={'Clear'} onClick={this.clearState} />
              <UploadButton children={'Upload'} onClick={this.clearState} />
              <UploadButton
                children={showJSONPreview ? 'Edit' : 'Preview'}
                onClick={() => {
                  this.setState({ showJSONPreview: !showJSONPreview });
                }}
              />
              <UploadButton children={'Download'} onClick={this.downloadFile} />
              <input
                css={{ marginLeft: '1em' }}
                type="range"
                min="30"
                max="250"
                onChange={e => this.setState({ inputRef: e.currentTarget.value })}
              />
              <div>{this.state.inputRef}</div>
            </Flex>
            <Flex justifyContent={'space-between'}>
              {
                // TODO: Need to unify onFileDrop and processJSON
              }
              <Dropzone style={dropZoneStyle} disableClick onDrop={this.onFileDrop}>
                {dropzoneActive && <div style={overlayStyle}>Drop files...</div>}
                {showJSONPreview && tableData ? (
                  <div className="code-toolbar" css={{ height: '100%', fontSize: '1.3em' }}>
                    <pre css={{ width: '100%', height: '100%' }}>
                      <code
                        ref={this.previewRef}
                        css={{ width: '100%', height: '100%', fontSize: '1.2em' }}
                        className="language-javascript line-numbers">
                        {`${JSON.stringify(tableData, null, 2)}`}
                      </code>
                    </pre>
                    <div className="toolbar">
                      <div className="toolbar-item">
                        <button onClick={this.createSelection} type="button">
                          Select Text
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <textarea
                    onPaste={this.processJSON}
                    onKeyUp={this.processJSON}
                    ref={this.textRef}
                    // autoFocus
                    onClick={() => {
                      this.setState({ showJSONPreview: false });
                    }}
                    onBlur={() => {
                      this.setState({ showJSONPreview: true });
                    }}
                    css={{ width: '100%', height: '100%', fontSize: '1.5em' }}
                    placeholder="Drag and Drop JSON, CSV, or XLSX. You can also type in JSON.">
                    {tableData && `${JSON.stringify(tableData, null, 2)}`}
                  </textarea>
                )}
              </Dropzone>
              {tableData && !showValidJSON && <Message bg="orange">Invalid JSON</Message>}
            </Flex>

            <Box mt={3}>
              {tableData && (
                <HotTable
                  id="hot"
                  data={tableData}
                  colHeaders={tableHeaders}
                  filter={true}
                  rowHeaders={true}
                  contextMenu={true}
                  dropdownMenu={true}
                  columnSorting={true}
                  manualColumnResize={true}
                  width="100%"
                  height={this.state.inputRef}
                  stretchH="all"
                  ref={
                    this.hotRef // renderAllRows={true}
                  }
                />
              )}
            </Box>
          </Column>
          <Column width={[1, 1 / 8]} />
        </Row>
      </Layout>
    );
  }
}
